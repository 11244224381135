@use "./config/" as *;
@forward "./main-scss/";
@forward "./swiper/swiper-bundle";
@forward "./partials/news-details";

.waf-gallery-page {
    padding-bottom: var(--space-12);
    .article-head .title {
        margin-bottom: 0;
        font-size: 2.4rem;
    }
    .article-body {
        .item-type-icon,.article-commentbox,.icon-time-wrapper,.reaction-section,.article-meta,.article-title {
            @extend %d-none;
        }
        .article-description {
            line-height: 1.6rem;
            @extend %mb-4;
            @extend %font-10-pm;
            @extend %neutral-800;
            p {
                color: inherit;
                font-size: inherit;
                @extend %pb-0;
            }
        }
    }
    .preview-swiper {
        .swiper-button-prev,.swiper-button-next {
            @extend %d-none;
        }
        .article {
            &-item {
                @extend %w-100;
            }
            &-thumbnail {
                @extend %mb-4;
            }
        }
    }
    .swiper {
        &-pagination {
            width: max-content;
            @include position(unset,null,var(--space-2),0);
            &-current {
                @extend %neutral-800;
                @extend %font-24-pb;
            }
            &-total {
                @extend %neutral-600;
                @extend %font-14-pm;
            }
        }
        &-button-prev,&-button-next {
            top: unset;
            bottom: var(--space-2);
            width: 3.4rem;
            &::after {
                font-size: 2rem;
            }
        }
        &-button-prev {
            left: auto;
            right: var(--space-12);
        }
        &-button-next {
            right: 0;
        }
    }
    .thumbnail-swiper  {
        padding-bottom: var(--space-15);
        .article-description {
            @extend %d-none;
        }
    }
    .article-item {
        &.waf-ad  {
            margin-inline: auto;
            margin-block: auto;
        }
    }
}
@include mq(col-lg) {
    .waf-gallery-page {
        .article-head .title {
            font-size: 3.2rem;
        }
        .article-head .head-wrap {
            margin-bottom: var(--space-6);
        }
        .article-body {
            position: relative;
            .article-description {
                font-size: 1.4rem;
                line-height: 2rem;
                margin-bottom: var(--space-6);
            }
        }
        .thumbnail-swiper {
            padding-bottom: 0;
            position: static;
            &::before {
                content: "";
                width: calc(50% - var(--space-9));
                height: .1rem;
                background: clr(neutral-800,2);
                @include position(null,null,null,var(--space-6));
            }
        }
        .swiper {
            &-pagination {
                left: var(--space-6);
                &-current {
                    font-size: 3.2rem;
                }
                &-total {
                    font-size: 1.6rem;
                }
            }
            &-button-prev,&-button-next,&-pagination {
                bottom: 6rem;
            }
            &-button-prev {
                right: calc(50% + var(--space-15));
            }
            &-button-next {
                right: calc(50% + var(--space-3));
            }
        }
        .thumbnail-swiper {
            margin-right: 0;
            width: 50%;
        }
    }
}